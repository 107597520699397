import type { Schemas } from '#shopware'

export const useDynamicPrice = (
  product: MaybeRef<Schemas['Product']>,
  quantity: MaybeRef<number> = 1
) => {
  const {
    unitPrice,
    displayFromVariants,
    displayFrom,
    isListPrice,
    tierPrices,
  } = useProductPrice(product)

  const b2cPrice = computed(() => {
    return product.value.extensions?.b2cPriceInfo?.b2cPrice
  })

  const price = computed(() => {
    const tierPrices = unref(product).calculatedPrices
    if (!tierPrices.length) return unitPrice.value

    // last price in the array corresponds to the exact quantity or above
    // all other prices are applicable for quantities until that point
    return (
      tierPrices.find((tierPrice) => tierPrice.quantity >= unref(quantity))
        ?.unitPrice ?? tierPrices[tierPrices.length - 1].unitPrice
    )
  })

  const total = computed(() => {
    if (!price.value || Number.isNaN(price.value)) return undefined
    return +(price.value * unref(quantity)).toFixed(2)
  })

  const base = computed(() => {
    const tierPrices = unref(product).calculatedPrices
    if (!tierPrices.length) return unitPrice.value

    return (
      tierPrices.find((tierPrice) => tierPrice.quantity >= unref(quantity))
        ?.listPrice.price ?? tierPrices[tierPrices.length - 1].listPrice.price
    )
  })

  const baseTotal = computed(() => {
    if (!base.value || Number.isNaN(base.value)) return undefined
    return +(base.value * unref(quantity)).toFixed(2)
  })

  return {
    price,
    total,
    baseTotal,
    displayFromVariants,
    displayFrom,
    isListPrice,
    tierPrices,
    b2cPrice,
  }
}
